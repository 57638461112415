import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import {
  BrowserRouter as Router,
  NavLink,
  Routes,
  Route,
} from "react-router-dom";
function Navbar5() {
  return (
    <Container>
      <Row className="all-height mt-5">
        <Col lg={3} xs={6}>
          <NavLink to="/sugar" className="btn" activeClassName="active">
            Sugar Candy
          </NavLink>
          <NavLink to="/jaggery" className="btn" activeClassName="active">
            Jaggery
          </NavLink>
          <NavLink to="/honey" className="btn" activeClassName="active">
            Honey
          </NavLink>
        </Col>
      </Row>
    </Container>
  );
}

export default Navbar5;
