import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Footer from "./Footer";
import spices from "../assets/GREEN GRAM.webp";
import Navbar4 from "./Navbar4";
import Productbg from "./Productbg";
function Green() {
  return (
    <div>
      <Productbg />
      <Container>
        <Row className="all-height">
          <Col lg={3} xs={6}>
            <Navbar4 />
          </Col>
          <Col lg={9}>
            <img src={spices} className="img-fluid" alt="Sugar" />
            <h5 className="title mt-3">ABOUT</h5>
            <p className="content1">
              Green gram, also known as mung beans or moong dal, is a highly
              nutritious legume that has been cultivated for centuries in
              various parts of the world, particularly in Asia. Derived from the
              seeds of the Vigna radiata plant, green gram boasts a rich content
              of nutrients and bioactive compounds, making it a valuable
              addition to various cuisines and a source of potential health
              benefits.
            </p>
            <p className="content1">
              One of the primary components of green gram is protein, making it
              an excellent plant-based protein source for vegetarians and
              vegans. Protein is essential for muscle growth and repair, as well
              as for the production of enzymes and hormones within the body.
              Green gram also contains dietary fiber, which aids in digestion,
              promotes satiety, and helps regulate blood sugar levels, making it
              beneficial for individuals managing diabetes or seeking to
              maintain a healthy weight.
            </p>
            <p className="content1">
              In addition to protein and fiber, green gram is rich in vitamins
              and minerals, including folate, iron, magnesium, and potassium.
              Folate is essential for DNA synthesis and cell division, making it
              particularly important during pregnancy for fetal development.
              Iron is necessary for oxygen transport in the blood, while
              magnesium and potassium are vital for nerve function, muscle
              contraction, and fluid balance within the body.
            </p>
            <p className="content1">
              Furthermore, green gram contains phytochemicals such as
              polyphenols and flavonoids, which possess antioxidant properties
              and may help protect against oxidative stress and inflammation.
              These compounds contribute to green gram's potential health
              benefits, including supporting heart health, reducing the risk of
              chronic diseases, and promoting overall well-being. Whether used
              to make traditional dishes like green gram curry, sprouts, or
              salads, or incorporated into modern recipes for soups, stews, and
              desserts, green gram offers both nutritional value and culinary
              versatility. Its rich content of nutrients and bioactive compounds
              makes green gram a valuable ingredient for promoting overall
              health and well-being when included as part of a balanced diet.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Green;
