import React, { useState } from "react";
import "../styles/Navbar.css";
import {
  BrowserRouter as Router,
  NavLink,
  Routes,
  Route,
} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Home from "./Home";
import About from "./About";
import Sugar from "./Sugar";
import Spices from "./Spices";
import Food from "../products/Food";
import Tea from "../products/Tea";
import Pulses from "../products/Pulses";
import Agro from "../products/Agro";
import Policy from "./Policy";
import Exports from "./Exports";
import Contact1 from "./Contact1";
import Quertly from "../investor/Quertly";
import Material from "../public/Material";
import Hr from "../careers/Hr";
import Ginger from "./Ginger";
import Cinnamon from "./Cinnamon";
import Pepper from "./Pepper";
import Tamarind from "./Tamarind";
import Asafoetida from "./Asafoetida";
import Turmeric from "./Turmeric";
import Funnel from "./Funnel";
import Garlic from "./Garlic";
import Mustard from "./Mustard";
import Nutmeg from "./Nutmeg";
import Anise from "./Anise";
import Cumin from "./Cumin";
import Maize from "./Maize";
import Ragi from "./Ragi";
import Bengal from "./Bengal";
import Horse from "./Horse";
import Green from "./Green";
import Dhall from "./Dhall";
import Cashew from "./Cashew";
import Arcanut from "./Arcanut";
import Jaggery from "./Jaggery";
import Honey from "./Honey";
import Chillis from "./Chillis";
import Poppy from "./Poppy";
import Sandal from "./Sandal";
import Oil from "./Oil";
import Gingelly from "./Gingelly";

function Navbar1() {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsSubMenuOpen(true);
  };

  const handleMouseLeave = () => {
    setIsSubMenuOpen(false);
  };
  return (
    <Router>
      <Navbar
        expand="lg"
        style={{
          backgroundColor: "#232323",
          position: "sticky",
          top: "0",
          zIndex: "1000",
        }}
        className="navbar"
      >
        <Container>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="navbar-toggler-icon"
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavLink to="/" className="nav-link" activeClassName="active">
                HOME
              </NavLink>
              <NavLink
                to="/about"
                className="nav-link"
                activeClassName="active"
              >
                ABOUT US
              </NavLink>
              <NavDropdown
                title="OUR PRODUCTS"
                id="basic-nav-dropdown"
                show={isSubMenuOpen}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <NavLink
                  to="/ginger"
                  className="dropdown-item"
                  activeClassName="active"
                >
                  Spices
                </NavLink>
                <NavLink
                  to="/maize"
                  className="dropdown-item"
                  activeClassName="active"
                >
                  Flour
                </NavLink>
                <NavLink
                  to="/bengal"
                  className="dropdown-item"
                  activeClassName="active"
                >
                  Gram
                </NavLink>
                <NavLink
                  to="/sugar"
                  className="dropdown-item"
                  activeClassName="active"
                >
                  Sugar
                </NavLink>
                <NavLink
                  to="/chillis"
                  className="dropdown-item"
                  activeClassName="active"
                >
                  Others
                </NavLink>
              </NavDropdown>
              {/* <NavLink
                to="/policy"
                className="nav-link"
                activeClassName="active"
              >
                POLICY
              </NavLink>

              <NavDropdown title="INVESTOR" id="basic-nav-dropdown">
                <NavLink
                  to="/quertly"
                  className="dropdown-item"
                  activeClassName="active"
                >
                  Quarterly Result
                </NavLink>
              </NavDropdown> */}
              <NavLink
                to="/material"
                className="nav-link"
                activeClassName="active"
              >
                MATERIAL DOCUMENTS
              </NavLink>
              {/* <NavDropdown title="PUBLIC ISSUE" id="basic-nav-dropdown">
                <NavLink
                  to="/material"
                  className="dropdown-item"
                  activeClassName="active"
                >
                  Material Documents
                </NavLink>
              </NavDropdown> */}
              {/* <NavDropdown title="CAREERS" id="basic-nav-dropdown">
                <NavLink
                  to="/hr"
                  className="dropdown-item"
                  activeClassName="active"
                >
                  Hr Philosopy
                </NavLink>
              </NavDropdown> */}
              <NavLink
                to="/exports"
                className="nav-link"
                activeClassName="active"
              >
                EXPORTS
              </NavLink>
              <NavLink
                to="/contact"
                className="nav-link"
                activeClassName="active"
              >
                CONTACT US
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/products/:id" element={<Sugar />} />
        <Route path="/ginger" element={<Ginger />} />
        <Route path="/food" element={<Food />} />
        <Route path="/tea" element={<Tea />} />
        <Route path="/pulses" element={<Pulses />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/quertly" element={<Quertly />} />
        <Route path="/material" element={<Material />} />
        <Route path="/hr" element={<Hr />} />
        <Route path="/exports" element={<Exports />} />
        <Route path="/contact" element={<Contact1 />} />
        <Route path="/cinnamon" element={<Cinnamon />} />
        <Route path="/pepper" element={<Pepper />} />
        <Route path="/tamarind" element={<Tamarind />} />
        <Route path="/asafoetida" element={<Asafoetida />} />
        <Route path="/turmeric" element={<Turmeric />} />
        <Route path="/funnel" element={<Funnel />} />
        <Route path="/garlic" element={<Garlic />} />
        <Route path="/mustard" element={<Mustard />} />
        <Route path="/nutmeg" element={<Nutmeg />} />
        <Route path="/anise" element={<Anise />} />
        <Route path="/cumin" element={<Cumin />} />
        <Route path="/maize" element={<Maize />} />
        <Route path="/ragi" element={<Ragi />} />
        <Route path="/bengal" element={<Bengal />} />
        <Route path="/horse" element={<Horse />} />
        <Route path="/green" element={<Green />} />
        <Route path="/dhall" element={<Dhall />} />
        <Route path="/cashew" element={<Cashew />} />
        <Route path="/arcanut" element={<Arcanut />} />
        <Route path="/sugar" element={<Sugar />} />
        <Route path="/jaggery" element={<Jaggery />} />
        <Route path="/honey" element={<Honey />} />
        <Route path="/chillis" element={<Chillis />} />
        <Route path="/poppy" element={<Poppy />} />
        <Route path="/sandal" element={<Sandal />} />
        <Route path="/oil" element={<Oil />} />
        <Route path="/gingelly" element={<Gingelly />} />
      </Routes>
    </Router>
  );
}

export default Navbar1;
