import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Footer from "./Footer";
import spices from "../assets/oil.webp";
import Navbar6 from "./Navbar6";
import Productbg from "./Productbg";
function Oil() {
  return (
    <div>
      <Productbg />
      <Container>
        <Row className="all-height">
          <Col lg={3} xs={6}>
            <Navbar6 />
          </Col>
          <Col lg={9}>
            <img src={spices} className="img-fluid" alt="Sugar" />
            <h5 className="title mt-3">ABOUT</h5>
            <p className="content1">
              In agricultural crops like soybeans, sunflowers, and peanuts, oil
              content plays a crucial role in determining the economic value of
              the harvest. Crops with higher oil content typically yield more
              oil per unit of weight, making them more desirable for oil
              extraction and processing. Farmers often select crop varieties
              with higher oil content to maximize their yields and profits.
            </p>
            <p className="content1">
              In the food industry, oil content affects the flavor, texture, and
              nutritional profile of food products. For example, nuts and seeds
              with higher oil content tend to have a richer flavor and creamier
              texture when processed into oils or nut butters. Additionally, oil
              content influences the shelf stability and cooking properties of
              cooking oils and fats, with oils high in unsaturated fats being
              more prone to oxidation and rancidity.
            </p>
            <p className="content1">
              In the production of biofuels like biodiesel, oil content is a
              critical factor in determining the feasibility and efficiency of
              the conversion process. Feedstocks with higher oil content, such
              as oilseeds and algae, are preferred for biodiesel production as
              they require less processing and yield higher biofuel output per
              unit of biomass. Overall, oil content plays a significant role in
              various aspects of agriculture, food production, and industrial
              processes. Accurate measurement and management of oil content are
              essential for optimizing yields, ensuring product quality, and
              meeting the diverse needs of consumers and industries alike.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Oil;
