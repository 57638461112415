import React from "react";
import "../styles/Food.css";
import { Container, Row, Col } from "react-bootstrap";
import bgimg from "../assets/download.jpg";
import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";
import Pulses1 from "../assets/pulses.jpg";

function Pulses() {
  return (
    <div>
      <Row>
        <Col
          lg={12}
          style={{ height: "100vh", width: "100%", overflow: "hidden" }}
        >
          <img
            src={bgimg}
            style={{ height: "50%", width: "100%", objectFit: "cover" }}
            alt="background"
          />
        </Col>
      </Row>
      <Container>
        <Row className="all-height">
          <Col lg={3} xs={6}>
            <NavLink
              to="/products/sugar"
              className="btn"
              activeClassName="active"
            >
              Sugar
            </NavLink>
            <NavLink to="/spices" className="btn" activeClassName="active">
              Spices
            </NavLink>
            <NavLink to="/food" className="btn" activeClassName="active">
              Food Grains
            </NavLink>
            <NavLink to="/tea" className="btn" activeClassName="active">
              Tea
            </NavLink>
            <NavLink to="/pulses" className="btn" activeClassName="active">
              Pulses
            </NavLink>
            <NavLink to="/agro" className="btn" activeClassName="active">
              Agro Feed
            </NavLink>
          </Col>
          <Col lg={9}>
            <img src={Pulses1} className="img-fluid" alt="Sugar" />
            <h5 className="title mt-3">ABOUT</h5>
            <p className="content">
              Sugar is the most important sweetener throughout the world.
              Sugarcane juice is processed into granulated crystals of Sugar. We
              deal in indian white crystal sugar.
            </p>
            <p className="content">
              Sugar is the most important sweetener throughout the world.
              Sugarcane juice is processed into granulated crystals of Sugar. We
              deal in indian white crystal sugar.
            </p>
            <p className="content">
              Sugar is the most important sweetener throughout the world.
              Sugarcane juice is processed into granulated crystals of Sugar. We
              deal in indian white crystal sugar.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Pulses;
