import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Footer from "./Footer";
import spices from "../assets/SUGAR CUBE.webp";
import Navbar5 from "./Navbar5";
import Productbg from "./Productbg";
function Sugar() {
  return (
    <div>
      <Productbg />
      <Container>
        <Row className="all-height">
          <Col lg={3} xs={6}>
            <Navbar5 />
          </Col>
          <Col lg={9}>
            <img src={spices} className="img-fluid" alt="Sugar" />
            <h5 className="title mt-3">ABOUT</h5>
            <p className="content1">
              Sugar candy, also known as rock candy or crystallized sugar, is a
              sweet treat that has been enjoyed for centuries in various
              cultures around the world. Made from sucrose, sugar candy is
              created through a process of crystallization, where sugar
              molecules form into solid crystals. This results in the formation
              of hard, translucent pieces of candy with a shiny appearance.
            </p>
            <p className="content1">
              The primary component of sugar candy is sucrose, a type of
              carbohydrate that provides a quick source of energy when consumed.
              Sucrose is a disaccharide composed of glucose and fructose
              molecules bonded together. When eaten, sucrose is broken down by
              digestive enzymes into its constituent sugars, which are then
              absorbed into the bloodstream to provide fuel for the body's
              cells.
            </p>
            <p className="content1">
              In addition to sucrose, sugar candy may contain small amounts of
              other compounds, depending on the method of production and any
              added flavorings or colorings. However, the ingredient list for
              sugar candy is typically minimal, consisting primarily of sucrose
              and water. While sugar candy is a delicious indulgence enjoyed by
              many, it's essential to consume it in moderation as part of a
              balanced diet. Excessive consumption of sugary foods like sugar
              candy can contribute to weight gain, tooth decay, and other health
              issues when consumed in excess. Therefore, it's best to enjoy
              sugar candy as an occasional treat rather than a regular part of
              your diet.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Sugar;
