import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import {
  BrowserRouter as Router,
  NavLink,
  Routes,
  Route,
} from "react-router-dom";
function Navbar6() {
  return (
    <Container>
      <Row className="all-height mt-5">
        <Col lg={3} xs={6}>
          <NavLink to="/chillis" className="btn" activeClassName="active">
            Chillis
          </NavLink>
          <NavLink to="/poppy" className="btn" activeClassName="active">
            Poppy Seeds
          </NavLink>
          <NavLink to="/sandal" className="btn" activeClassName="active">
            Sandle
          </NavLink>
          <NavLink to="/oil" className="btn" activeClassName="active">
            Oil
          </NavLink>
          <NavLink to="/gingelly" className="btn" activeClassName="active">
            Gingelly Seed
          </NavLink>
        </Col>
      </Row>
    </Container>
  );
}

export default Navbar6;
