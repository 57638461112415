import React from "react";
import "../styles/Policy.css";
import { Container, Row, Col } from "react-bootstrap";
import bgimg from "../assets/download.jpg";
import Footer from "./Footer";
import { NavLink } from "react-router-dom";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import pdf from "../assets/PDF_file_icon.svg.png";
import banner from "../assets/widget-banner.jpg";
function Policy() {
  return (
    <div>
      <Row>
        <Col
          lg={12}
          style={{ height: "100vh", width: "100%", overflow: "hidden" }}
        >
          <img
            src={bgimg}
            style={{ height: "50%", width: "100%", objectFit: "cover" }}
            alt="background"
          />
        </Col>
      </Row>
      <Container>
        <Row style={{ marginTop: "-300px" }}>
          <Col lg={3} xs={12}>
            <div style={{ position: "relative" }}>
              <img
                src={banner}
                style={{ zIndex: "-1", position: "absolute", top: 0, left: 0 }}
              />
              <div
                style={{
                  position: "relative",
                  zIndex: 1,
                  paddingTop: "40px",
                  paddingLeft: "20px",
                }}
              >
                <PhoneOutlinedIcon className="icon" />
                <p className="banner-title">How We Can Help You</p>
                <p style={{ color: "white", fontSize: "20px" }}>
                  If you need any help, contact us
                </p>
                <PhoneOutlinedIcon
                  style={{ fontSize: "20px", color: "d57315" }}
                />
                <span style={{ color: "white" }}>+92 32434343434</span>
              </div>
            </div>
          </Col>

          <Col
            lg={3}
            xs={12}
            className="d-flex justify-content-center align-items-center"
          >
            <div className="section-title clearfix">
              <div>
                <h5 className="title-header">CERTIFICATION</h5>
                <img
                  src={pdf}
                  alt="Avatar"
                  style={{
                    width: "200px",
                    height: "200px",
                  }}
                />
                <h3>Name</h3>
              </div>
            </div>
          </Col>
          <Col
            lg={{ span: 3 }}
            className="d-flex justify-content-center align-items-center"
          >
            <div>
              <img
                src={pdf}
                alt="Avatar"
                style={{
                  width: "200px",
                  height: "200px",
                }}
              />
              <h3>Name</h3>
            </div>
          </Col>
          <Col
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <div>
              <img
                src={pdf}
                alt="Avatar"
                style={{
                  width: "200px",
                  height: "200px",
                }}
              />
              <h3>Name</h3>
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            lg={{ span: 3, offset: 3 }}
            className="d-flex justify-content-center align-items-center "
          >
            <div>
              <img
                src={pdf}
                alt="Avatar"
                style={{
                  width: "200px",
                  height: "200px",
                }}
              />
              <h3>Name</h3>
            </div>
          </Col>
          <Col
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <div>
              <img
                src={pdf}
                alt="Avatar"
                style={{
                  width: "200px",
                  height: "200px",
                }}
              />
              <h3>Name</h3>
            </div>
          </Col>
          <Col
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <div>
              <img
                src={pdf}
                alt="Avatar"
                style={{
                  width: "200px",
                  height: "200px",
                }}
              />
              <h3>Name</h3>
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            lg={{ span: 3, offset: 3 }}
            className="d-flex justify-content-center align-items-center "
          >
            <div>
              <img
                src={pdf}
                alt="Avatar"
                style={{
                  width: "200px",
                  height: "200px",
                }}
              />
              <h3>Name</h3>
            </div>
          </Col>
          <Col
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <div>
              <img
                src={pdf}
                alt="Avatar"
                style={{
                  width: "200px",
                  height: "200px",
                }}
              />
              <h3>Name</h3>
            </div>
          </Col>
          <Col
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <div>
              <img
                src={pdf}
                alt="Avatar"
                style={{
                  width: "200px",
                  height: "200px",
                }}
              />
              <h3>Name</h3>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Policy;
