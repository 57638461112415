import React, { useState } from "react";
import "../styles/Contact1.css";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import bgimg from "../assets/main.webp";
import Footer from "./Footer";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import { FaMapMarkerAlt } from "react-icons/fa";
import axios from "axios";
import Productbg from "./Productbg";
function Contact1() {
  const [formData, setFormData] = useState({
    Name: "",
    mail: "",
    query: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://vebbox.in/iindiamartbackend/controllers/api/post/gmail.php",
        formData
      ); // Adjust the URL as per your server endpoint
      console.log(response.data); // Log response from server
      // Optionally, you can reset the form after successful submission
      setFormData({
        Name: "",
        mail: "",
        query: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <Productbg />

      <Container fluid style={{ backgroundColor: "snow", marginTop: "-340px" }}>
        <Row>
          <Col>
            <Container style={{ backgroundColor: "white" }}>
              <Row>
                <Col lg={4} style={{ paddingLeft: "20px" }}>
                  <PhoneOutlinedIcon
                    style={{
                      fontSize: "50px",
                      marginTop: "40px",
                      color: "d57315",
                    }}
                  />
                  <p
                    className="phoneno"
                    style={{
                      fontSize: "15px",
                      marginTop: "-50px",
                      marginLeft: "50px",
                    }}
                  >
                    <b>+91-7358805707</b>
                  </p>
                  <p
                    style={{
                      marginLeft: "53px",
                      color: "#8b8b8b",
                      fontSize: "15px",
                    }}
                  >
                    Phone
                  </p>
                </Col>
                <Col lg={4}>
                  <DraftsOutlinedIcon
                    style={{
                      fontSize: "50px",
                      marginTop: "40px",
                      color: "d57315",
                    }}
                  />
                  <p
                    className="phoneno"
                    style={{
                      fontSize: "15px",
                      marginTop: "-50px",
                      marginLeft: "55px",
                    }}
                  >
                    <b>hiimhighindia@gmail.com</b>
                  </p>
                  <p
                    style={{
                      marginLeft: "53px",
                      color: "#8b8b8b",
                      fontSize: "15px",
                    }}
                  >
                    Make A Call
                  </p>
                </Col>
                <Col lg={4}>
                  <PublicOutlinedIcon
                    style={{
                      fontSize: "50px",
                      marginTop: "40px",
                      color: "d57315",
                    }}
                  />
                  <p
                    className="phoneno"
                    style={{
                      fontSize: "15px",
                      marginTop: "-50px",
                      marginLeft: "55px",
                    }}
                  >
                    <b>Thiruvarur-TamilNadu-India-614017</b>
                  </p>
                  <p
                    style={{
                      marginLeft: "53px",
                      color: "#8b8b8b",
                      fontSize: "15px",
                    }}
                  >
                    Location
                  </p>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <FaMapMarkerAlt
                    style={{
                      fontSize: "50px",
                      marginTop: "40px",
                      color: "d57315",
                    }}
                  />
                  <p
                    className="phoneno"
                    style={{
                      fontSize: "15px",
                      marginTop: "-50px",
                      marginLeft: "55px",
                    }}
                  >
                    <b>Address</b>
                  </p>
                  <p
                    style={{
                      marginLeft: "53px",
                      color: "#8b8b8b",
                      fontSize: "15px",
                    }}
                  >
                    HIGH INDIA INTERNATIONAL MARKETING
                  </p>
                  <p
                    style={{
                      marginLeft: "53px",
                      color: "#8b8b8b",
                      fontSize: "15px",
                    }}
                  >
                    3/393 North street ,
                  </p>
                  <p
                    style={{
                      marginLeft: "53px",
                      color: "#8b8b8b",
                      fontSize: "15px",
                    }}
                  >
                    Thachanvayal - Kottur,
                  </p>
                  <p
                    style={{
                      marginLeft: "53px",
                      color: "#8b8b8b",
                      fontSize: "15px",
                    }}
                  >
                    MANNARGUDI ,
                  </p>
                  <p
                    style={{
                      marginLeft: "53px",
                      color: "#8b8b8b",
                      fontSize: "15px",
                    }}
                  >
                    THIRUVARUR - 614 017
                  </p>
                  <p
                    style={{
                      marginLeft: "53px",
                      color: "#8b8b8b",
                      fontSize: "15px",
                    }}
                  >
                    TAMIL NADU - INDIA
                  </p>
                </Col>
                <Col lg={{ span: 6, offset: 1 }} className="mt-4">
                  <h2 className="title">Contact Us</h2>
                  <Form onSubmit={handleSubmit} className="mt-3">
                    <Col lg={12}>
                      <Form.Group controlId="Name">
                        <Form.Control
                          type="text"
                          placeholder="Full Name"
                          name="Name"
                          value={formData.Name}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group controlId="mail" className="mt-3">
                        <Form.Control
                          type="email"
                          placeholder="Enter Email"
                          name="mail"
                          value={formData.mail}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      {/* Uncomment this section if you want to include phone number field */}
                      {/* <Form.Group controlId="phoneNumber" className="mt-3">
              <Form.Control
                type="number"
                placeholder="Phone Number"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </Form.Group> */}
                      <Form.Group controlId="query" className="mt-3">
                        <Form.Control
                          type="text"
                          placeholder="Enter Query"
                          name="query"
                          value={formData.query}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                      <Button
                        style={{ color: "black", width: "100%" }}
                        type="submit"
                        className="mt-3"
                      >
                        Submit
                      </Button>
                    </Col>
                  </Form>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      {/* <Container>
        <Row>
          <Col lg={6}></Col>
          <Col lg={6}>
            <h2 className="title">Contact Us</h2>
            <Form onSubmit={handleSubmit} className="mt-3">
              <Col lg={12}>
                <Form.Group controlId="Name">
                  <Form.Control
                    type="text"
                    placeholder="Full Name"
                    name="Name"
                    value={formData.Name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="mail" className="mt-3">
                  <Form.Control
                    type="email"
                    placeholder="Enter Email"
                    name="mail"
                    value={formData.mail}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group controlId="query" className="mt-3">
                  <Form.Control
                    type="text"
                    placeholder="Enter Query"
                    name="query"
                    value={formData.query}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Button
                  style={{ color: "black", width: "100%" }}
                  type="submit"
                  className="mt-3"
                >
                  Submit
                </Button>
              </Col>
            </Form>
          </Col>
        </Row>
      </Container> */}
      <Footer />
    </>
  );
}

export default Contact1;
