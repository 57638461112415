import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col } from "react-bootstrap";
import bgimg from "../assets/download.jpg";
import Footer from "./Footer";
import spices from "../assets/GINGER.webp";
import Navbar3 from "./Navbar3";

import Productbg from "./Productbg";
function Ginger() {
  return (
    <div>
      <Productbg />
      <Container>
        <Row className="all-height">
          <Col lg={3} xs={6}>
            <Navbar3 />
          </Col>
          <Col lg={9}>
            <div>
              <img src={spices} className="img-fluid" alt="Sugar" />
              <h5 className="title mt-3">ABOUT</h5>
            </div>

            <p className="content1">
              The ginger content in foods can vary depending on the recipe and
              how it's prepared. Fresh ginger root typically contains about 1-2%
              gingerol by weight, which is the compound responsible for ginger's
              characteristic flavor and potential health benefits. However, when
              ginger is dried or processed into products like ginger powder or
              ginger paste, the concentration of gingerol can vary. For specific
              products or dishes, it's best to check the ingredient list or
              nutritional information for the ginger content.
            </p>

            <p className="content1">
              Ginger, botanically known as Zingiber officinale, is a flowering
              plant that's widely used as a spice and a folk medicine. Its main
              bioactive compound, gingerol, is responsible for its distinct
              flavor and potential health benefits. In addition to gingerol,
              ginger also contains other bioactive compounds such as shogaols,
              paradols, and zingerone.
            </p>
            <p className="content1">
              When consumed in moderation, ginger is considered safe for most
              people and is commonly used to alleviate nausea, aid digestion,
              reduce inflammation, and relieve muscle soreness. It can be
              consumed fresh, dried, powdered, or as an extract in various
              culinary dishes, teas, and supplements.
            </p>
            <p className="content1">
              In terms of nutritional content, ginger is low in calories and
              contains small amounts of essential nutrients like vitamin C,
              potassium, and manganese. However, its true value lies in its
              unique phytochemical composition and potential health-promoting
              properties.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Ginger;
