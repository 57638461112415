import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import {
  BrowserRouter as Router,
  NavLink,
  Routes,
  Route,
} from "react-router-dom";
import Footer from "./Footer";
import spices from "../assets/RAGI FLOUR.webp";
import Productbg from "./Productbg";
function Ragi() {
  return (
    <div>
      <Productbg />
      <Container>
        <Row className="all-height">
          <Col lg={3} xs={6}>
            <NavLink to="/maize" className="btn" activeClassName="active">
              Maize Flour
            </NavLink>
            <NavLink to="/ragi" className="btn" activeClassName="active">
              Ragi Flour
            </NavLink>
          </Col>
          <Col lg={9}>
            <img src={spices} className="img-fluid" alt="Sugar" />
            <h5 className="title mt-3">ABOUT</h5>
            <p className="content1">
              Ragi flour, also known as finger millet flour, is a nutritious and
              versatile ingredient that has been a dietary staple in many parts
              of the world, particularly in India and Africa, for centuries. It
              is derived from the ground grains of the ragi plant (Eleusine
              coracana), a hardy, drought-resistant cereal crop that thrives in
              diverse environmental conditions. Ragi flour boasts a rich content
              of nutrients and bioactive compounds, making it a valuable
              addition to various dishes and a source of potential health
              benefits.
            </p>
            <p className="content1">
              The primary component of ragi flour is carbohydrates,
              predominantly in the form of starch. However, unlike refined
              grains, ragi flour retains much of its natural fiber content,
              which contributes to its low glycemic index and helps regulate
              blood sugar levels. This makes ragi flour an excellent choice for
              individuals managing diabetes or seeking to maintain stable energy
              levels throughout the day.
            </p>
            <p className="content1">
              In addition to carbohydrates and fiber, ragi flour is also a good
              source of protein, particularly when compared to other cereal
              grains. It contains essential amino acids, making it a valuable
              plant-based protein source for vegetarians and vegans.
              Furthermore, ragi flour is rich in vitamins and minerals,
              including calcium, iron, magnesium, and vitamin B-complex, which
              are essential for bone health, blood oxygenation, muscle function,
              and overall well-being.
            </p>
            <p className="content1">
              Moreover, ragi flour contains phytochemicals such as polyphenols
              and flavonoids, which possess antioxidant properties and may help
              protect against oxidative stress and inflammation. These compounds
              contribute to ragi flour's potential health benefits, including
              supporting heart health, reducing the risk of chronic diseases,
              and promoting longevity. Whether used to make traditional dishes
              like ragi roti, dosa, or porridge, or incorporated into modern
              recipes for baked goods, pancakes, and smoothies, ragi flour
              offers both nutritional value and culinary versatility. Its rich
              content of nutrients and bioactive compounds makes ragi flour a
              valuable ingredient for promoting overall health and well-being
              when included as part of a balanced diet.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Ragi;
