import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Footer from "./Footer";
import spices from "../assets/GARLIC.webp";
import Navbar3 from "./Navbar3";
import Productbg from "./Productbg";
function Garlic() {
  return (
    <div>
      <Productbg />
      <Container>
        <Row className="all-height">
          <Col lg={3} xs={6}>
            <Navbar3 />
          </Col>
          <Col lg={9}>
            <img src={spices} className="img-fluid" alt="Sugar" />
            <h5 className="title mt-3">ABOUT</h5>
            <p className="content1">
              Garlic, with its pungent aroma and distinctive flavor, has long
              been celebrated both as a culinary staple and a medicinal marvel.
              This bulbous plant, a member of the Allium genus, is prized for
              its rich content of bioactive compounds, particularly allicin.
              Allicin, formed when garlic cloves are crushed or chopped, is
              responsible for many of garlic's health benefits, including its
              antimicrobial and cardiovascular-protective properties.
            </p>
            <p className="content1">
              In addition to allicin, garlic contains a host of other
              sulfur-containing compounds, such as diallyl disulfide and S-allyl
              cysteine, which contribute to its characteristic taste and aroma.
              These compounds are not only responsible for garlic's distinctive
              flavor profile but also play a role in its potential
              health-promoting effects.
            </p>
            <p className="content1">
              Garlic is also a good source of vitamins and minerals, including
              vitamin C, vitamin B6, and manganese, as well as antioxidants such
              as selenium. These nutrients contribute to garlic's overall
              nutritional value and its potential to support immune function and
              combat oxidative stress.
            </p>
            <p className="content1">
              Whether used raw, cooked, or as a supplement, garlic has been
              revered for centuries for its culinary versatility and medicinal
              properties. From flavoring dishes in cuisines around the world to
              being employed in traditional medicine practices, garlic continues
              to hold a cherished place in both the kitchen and the medicine
              cabinet.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Garlic;
