import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col } from "react-bootstrap";
import bgimg from "../assets/download.jpg";
import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import pdf from "../assets/PDF_file_icon.svg.png";
import banner from "../assets/widget-banner.jpg";
function Quertly() {
  return (
    <div>
      <Row>
        <Col
          lg={12}
          style={{ height: "100vh", width: "100%", overflow: "hidden" }}
        >
          <img
            src={bgimg}
            style={{ height: "50%", width: "100%", objectFit: "cover" }}
            alt="background"
          />
        </Col>
      </Row>
      <Container>
        <Row style={{ marginTop: "-300px" }}>
          <Col lg={3}>
            <div style={{ position: "relative" }}>
              <img
                src={banner}
                style={{ zIndex: "-1", position: "absolute", top: 0, left: 0 }}
              />
              <div style={{ position: "relative", zIndex: 1, padding: "40px" }}>
                <PhoneOutlinedIcon
                  style={{ fontSize: "50px", color: "d57315" }}
                />
                <p
                  style={{
                    color: "white",
                    marginTop: "30px",
                    fontSize: "30px",
                  }}
                >
                  How We Can Help You
                </p>
                <p style={{ color: "white", fontSize: "20px" }}>
                  If you need any help, contact us
                </p>
                <PhoneOutlinedIcon
                  style={{ fontSize: "20px", color: "d57315" }}
                />
                <span style={{ color: "white" }}>+92 32434343434</span>
              </div>
            </div>
          </Col>
          <Col
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <div className="section-title clearfix">
              <div className="title-header">
                <h5 style={{ color: "#d57315", fontSize: "30px" }}>
                  Quertly Investor
                </h5>
                <div>
                  <img
                    src={pdf}
                    alt="Avatar"
                    style={{
                      width: "200px",
                      height: "200px",
                    }}
                  />
                  <h3>Name</h3>
                </div>
              </div>
            </div>
          </Col>
          <Col
            lg={{ span: 3 }}
            className="d-flex justify-content-center align-items-center"
          >
            <div>
              <img
                src={pdf}
                alt="Avatar"
                style={{
                  width: "200px",
                  height: "200px",
                }}
              />
              <h3>Name</h3>
            </div>
          </Col>
          <Col
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <div>
              <img
                src={pdf}
                alt="Avatar"
                style={{
                  width: "200px",
                  height: "200px",
                }}
              />
              <h3>Name</h3>
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            lg={{ span: 3, offset: 3 }}
            className="d-flex justify-content-center align-items-center "
          >
            <div>
              <img
                src={pdf}
                alt="Avatar"
                style={{
                  width: "200px",
                  height: "200px",
                }}
              />
              <h3>Name</h3>
            </div>
          </Col>
          <Col
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <div>
              <img
                src={pdf}
                alt="Avatar"
                style={{
                  width: "200px",
                  height: "200px",
                }}
              />
              <h3>Name</h3>
            </div>
          </Col>
          <Col
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <div>
              <img
                src={pdf}
                alt="Avatar"
                style={{
                  width: "200px",
                  height: "200px",
                }}
              />
              <h3>Name</h3>
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            lg={{ span: 3, offset: 3 }}
            className="d-flex justify-content-center align-items-center "
          >
            <div>
              <img
                src={pdf}
                alt="Avatar"
                style={{
                  width: "200px",
                  height: "200px",
                }}
              />
              <h3>Name</h3>
            </div>
          </Col>
          <Col
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <div>
              <img
                src={pdf}
                alt="Avatar"
                style={{
                  width: "200px",
                  height: "200px",
                }}
              />
              <h3>Name</h3>
            </div>
          </Col>
          <Col
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <div>
              <img
                src={pdf}
                alt="Avatar"
                style={{
                  width: "200px",
                  height: "200px",
                }}
              />
              <h3>Name</h3>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Quertly;
