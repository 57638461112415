import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Footer from "./Footer";
import spices from "../assets/FUNNEL.webp";
import Navbar3 from "./Navbar3";
import Productbg from "./Productbg";
function Funnel() {
  return (
    <div>
      <Productbg />
      <Container>
        <Row className="all-height">
          <Col lg={3} xs={6}>
            <Navbar3 />
          </Col>
          <Col lg={9}>
            <img src={spices} className="img-fluid" alt="Sugar" />
            <h5 className="title mt-3">ABOUT</h5>
            <p className="content1">
              Funnels, those seemingly simple yet indispensable tools, play a
              crucial role in a myriad of industries and tasks. Consisting of a
              cone-shaped structure with a narrow stem, funnels facilitate the
              controlled transfer of liquids or fine-grained substances from one
              container to another. The design of the funnel is ingeniously
              simple yet remarkably effective, allowing for precise pouring
              without spillage or wastage.
            </p>
            <p className="content1">
              In laboratories, funnels are essential equipment for tasks ranging
              from filtration to decantation. They enable scientists and
              researchers to separate solids from liquids or to transfer
              solutions with accuracy and efficiency. From chemistry to biology,
              funnels are ubiquitous, serving as indispensable aids in
              experimental procedures and analytical processes.
            </p>
            <p className="content1">
              Beyond the confines of laboratories, funnels find application in a
              wide array of everyday tasks. In kitchens, they are invaluable
              tools for pouring liquids into bottles or jars, ensuring a
              mess-free and precise transfer. Similarly, in automotive
              maintenance, funnels facilitate the replenishment of fluids such
              as oil or coolant in engines, minimizing the risk of spills and
              contamination.
            </p>
            <p className="content1">
              The versatility of funnels extends to industries such as
              manufacturing, where they are utilized in processes ranging from
              filling containers with granular materials to transferring liquids
              during production. Whether in small-scale operations or
              large-scale industrial settings, funnels streamline processes,
              enhance efficiency, and reduce waste, making them indispensable
              components of countless workflows.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Funnel;
