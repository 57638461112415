import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import bgimg from "../assets/download.jpg";
import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";
// import sugar from "../assets/sugar.jpg";
function Hr() {
  return (
    <div>
      <Row>
        <Col
          lg={12}
          style={{ height: "100vh", width: "100%", overflow: "hidden" }}
        >
          <img
            src={bgimg}
            style={{ height: "50%", width: "100%", objectFit: "cover" }}
            alt="background"
          />
        </Col>
      </Row>
      <Container>
        <Row style={{ marginTop: "-300px" }}>
          <Col lg={3}>
            <NavLink to="/hr" className="btn" activeClassName="active">
              Hr Philosopy
            </NavLink>
          </Col>
          <Col lg={9}>
            {/* <img src={sugar} className="img-fluid" alt="Sugar" /> */}
            <h5 className="title mt-3">HR Philosophy</h5>
            <p className="content">
              "The success of High India International Marketing hinges on
              cultivating a cohesive team of motivated and innovative
              individuals who are integral to driving the company's growth
              trajectory.
            </p>
            <p className="content">
              We are committed to fostering an entrepreneurial spirit among our
              team members at High India International Marketing, providing
              continuous training and support to foster success at both
              individual and corporate levels. Joining our team offers the
              opportunity to develop a challenging and diverse career path.
            </p>
            <p className="content">
              To be a part of High India International Marketing, all you need
              is the drive to strive for global standards, an appreciation for
              the values of perseverance and inclusiveness, and a steadfast
              commitment to doing what's right at all times. If you possess
              these qualities, we invite you to submit your resumes to
              hiimhighindia@gmail.com today.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Hr;
