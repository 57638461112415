import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Footer from "./Footer";
import spices from "../assets/GIGELLY OIL.webp";
import Navbar6 from "./Navbar6";
import Productbg from "./Productbg";
function Gingelly() {
  return (
    <div>
      <Productbg />
      <Container>
        <Row className="all-height">
          <Col lg={3} xs={6}>
            <Navbar6 />
          </Col>
          <Col lg={9}>
            <img src={spices} className="img-fluid" alt="Sugar" />
            <h5 className="title mt-3">ABOUT</h5>
            <p className="content1">
              Gingelly oil, also known as sesame oil, is a popular cooking oil
              with a rich history of culinary and medicinal uses. Derived from
              sesame seeds (Sesamum indicum), gingelly oil is renowned for its
              distinctive nutty flavor, high smoke point, and numerous health
              benefits. The oil content of gingelly oil is a crucial aspect that
              contributes to its nutritional value and culinary versatility.
              Sesame seeds are known for their relatively high oil content,
              ranging from about 40% to 60%, depending on the variety and
              growing conditions. This high oil content makes sesame seeds an
              excellent source of oil for extraction, resulting in a flavorful
              and aromatic oil that is prized in cooking.
            </p>
            <p className="content1">
              Gingelly oil is rich in unsaturated fats, particularly omega-6
              fatty acids like linoleic acid, which are essential for heart
              health and overall well-being. It also contains small amounts of
              omega-3 fatty acids, antioxidants, and phytochemicals, which
              contribute to its potential health benefits, including reducing
              inflammation, supporting skin health, and promoting cardiovascular
              health. In addition to its nutritional profile, gingelly oil has a
              high smoke point, making it suitable for various cooking methods,
              including frying, sautéing, and stir-frying. Its nutty flavor and
              aroma add depth and complexity to dishes, making it a favorite
              ingredient in Asian, Middle Eastern, and Mediterranean cuisines.
            </p>
            <p className="content1">
              Furthermore, gingelly oil has been used in traditional medicine
              systems like Ayurveda for its therapeutic properties. It is
              believed to have warming and grounding effects on the body, making
              it beneficial for balancing the doshas (energies) and promoting
              overall health and well-being. Overall, gingelly oil's high oil
              content, nutritional profile, and culinary versatility make it a
              valuable ingredient in the kitchen and a cherished component of
              many cuisines and cultures worldwide. Whether used for cooking,
              seasoning, or as a dietary supplement, gingelly oil offers both
              flavor and health benefits that have stood the test of time.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Gingelly;
