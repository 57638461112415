import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Footer from "./Footer";
import spices from "../assets/HONEY.webp";
import Navbar5 from "./Navbar5";
import Productbg from "./Productbg";
function Honey() {
  return (
    <div>
      <Productbg />
      <Container>
        <Row className="all-height">
          <Col lg={3} xs={6}>
            <Navbar5 />
          </Col>
          <Col lg={9}>
            <img src={spices} className="img-fluid" alt="Sugar" />
            <h5 className="title mt-3">ABOUT</h5>
            <p className="content1">
              Honey, often referred to as nature's golden elixir, is a natural
              sweetener produced by honey bees from the nectar of flowers. It
              has been treasured by civilizations throughout history for its
              delicious flavor, versatility, and numerous health benefits. Honey
              boasts a rich content of nutrients and bioactive compounds, making
              it not only a delightful culinary ingredient but also a valuable
              source of wellness.
            </p>
            <p className="content1">
              The primary component of honey is carbohydrates, primarily in the
              form of sugars such as fructose and glucose. These sugars provide
              a quick source of energy, making honey a popular choice for
              athletes and individuals seeking a natural energy boost. Unlike
              refined sugars, which are quickly absorbed into the bloodstream,
              the sugars in honey are accompanied by trace amounts of vitamins,
              minerals, and enzymes that contribute to its overall nutritional
              profile.
            </p>
            <p className="content1">
              In addition to carbohydrates, honey contains small amounts of
              vitamins and minerals, including B vitamins such as riboflavin,
              niacin, and pantothenic acid, as well as minerals like calcium,
              iron, magnesium, and potassium. While these nutrients are present
              in relatively low concentrations, they still contribute to honey's
              overall nutritional value.
            </p>
            <p className="content1">
              Furthermore, honey is rich in antioxidants, including flavonoids
              and phenolic acids, which help protect the body from oxidative
              stress and inflammation. These antioxidants may play a role in
              reducing the risk of chronic diseases such as heart disease,
              cancer, and neurodegenerative disorders. Moreover, honey possesses
              antimicrobial properties due to its low pH, high sugar content,
              and the presence of natural compounds like hydrogen peroxide and
              bee-derived peptides. These properties make honey an effective
              natural remedy for treating minor wounds, burns, and sore throats.
            </p>
            <p className="content1">
              While honey is undoubtedly a sweet treat, it's essential to
              consume it in moderation as part of a balanced diet. Excessive
              consumption of honey can contribute to weight gain and other
              health issues, particularly in individuals with diabetes or
              insulin resistance. Therefore, it's best to enjoy honey as a
              natural sweetener in place of refined sugars, while also
              appreciating its nutritional and medicinal benefits.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Honey;
