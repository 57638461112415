import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Footer from "./Footer";
import spices from "../assets/CASHEW NUT.webp";
import Navbar4 from "./Navbar4";
import Productbg from "./Productbg";
function Cashew() {
  return (
    <div>
      <Productbg />
      <Container>
        <Row className="all-height">
          <Col lg={3} xs={6}>
            <Navbar4 />
          </Col>
          <Col lg={9}>
            <img src={spices} className="img-fluid" alt="Sugar" />
            <h5 className="title mt-3">ABOUT</h5>
            <p className="content1">
              Cashews, with their creamy texture and buttery flavor, are popular
              nuts enjoyed around the world both as a snack and as a versatile
              ingredient in various culinary creations. Derived from the cashew
              tree (Anacardium occidentale), native to Brazil but now cultivated
              in many tropical regions, cashews boast a rich content of
              nutrients and bioactive compounds that contribute to their unique
              taste and potential health benefits.
            </p>
            <p className="content1">
              One of the primary components of cashews is healthy fats,
              particularly monounsaturated and polyunsaturated fats. These fats
              are beneficial for heart health, as they can help lower LDL (bad)
              cholesterol levels and reduce the risk of cardiovascular disease
              when consumed as part of a balanced diet. Cashews are also a good
              source of protein, making them a valuable plant-based protein
              option for vegetarians and vegans.
            </p>
            <p className="content1">
              In addition to healthy fats and protein, cashews are rich in
              vitamins and minerals, including vitamin E, magnesium, phosphorus,
              and copper. Vitamin E is a powerful antioxidant that helps protect
              cells from damage caused by free radicals, while magnesium and
              phosphorus are essential for bone health, muscle function, and
              energy metabolism. Copper is necessary for the production of red
              blood cells and collagen, as well as for maintaining a healthy
              immune system.
            </p>
            <p className="content1">
              Furthermore, cashews contain phytochemicals such as flavonoids and
              phenolic compounds, which possess antioxidant properties and may
              help reduce inflammation and lower the risk of chronic diseases
              such as cancer and diabetes. These compounds contribute to
              cashews' potential health benefits, including supporting immune
              function, promoting skin health, and improving overall well-being.
              Whether enjoyed on their own as a nutritious snack, added to trail
              mixes, salads, or stir-fries, or used to make creamy sauces,
              dairy-free cheeses, or decadent desserts, cashews offer both
              culinary versatility and nutritional value. Their rich content of
              nutrients and bioactive compounds makes cashews a valuable
              addition to a balanced diet for promoting overall health and
              well-being.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Cashew;
