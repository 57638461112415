import React from "react";
import "../styles/Exports.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import bgimg from "../assets/main.webp";
import Footer from "./Footer";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import exports1 from "../assets/imp exp.webp";
import banner from "../assets/widget-banner.jpg";
import Productbg from "./Productbg";
function Exports() {
  return (
    <div>
      <Productbg />
      <Container>
        <Row className="top">
          <Col lg={9} className="two order-lg-last">
            <img src={exports1} className="img-fluid" alt="Sugar" />
            <h5 className="title ">Exports</h5>
            <p className="content">
              High India International Marketing stands out as a reputable
              supplier and exporter of a diverse range of agricultural products,
              encompassing Chillies, Maize, Wheat, Maida (Wheat Flour), Garlic,
              Ginger, Groundnuts, Potato, Onion, Rice, Wheat Sugar, Turmeric
              (Finger & Bulb), Bajra, Barley, Fresh Fruits (Grapes, Pomegranate,
              Orange), Jeera, Matar Dal, Salt, Sorghum, Soya Doc, Rice Bran Doc
              & Tea.
            </p>
            <p className="content">
              Renowned globally for their superior quality and unwavering
              reliability, our products enjoy widespread acclaim. With an
              extensive network spanning across India and beyond, including
              regions such as Asia, the Middle East, Sri Lanka, Malaysia,
              Pakistan, Bangladesh, Dubai, UAE, Singapore, Djibouti, and Benin,
              we cater to a broad spectrum of markets. Furthermore, we
              consistently receive inquiries from various other parts of the
              world, reflecting the international appeal of our agricultural
              import offerings.
            </p>
            <h5 className="title mt-3">Imports</h5>
            <p className="content">
              High India International Marketing specializes in the importation
              of a wide variety of pulses sourced from Canada, Australia, and
              Myanmar. These imported pulses are distributed and sold throughout
              India, catering to a diverse domestic market. Our extensive
              network ensures that these agricultural imports reach every corner
              of the country, serving the needs of consumers nationwide.
              Additionally, our commitment to quality and reliability continues
              to foster strong relationships with suppliers and customers alike,
              solidifying our position as a trusted importer and distributor of
              agricultural products in India.
            </p>
          </Col>

          <Col lg={3} className="one order-lg-first">
            {/* Set order-lg-first to change order on smaller screens */}
            <div style={{ position: "relative" }}>
              <img
                src={banner}
                style={{ zIndex: "-1", position: "absolute", top: 0, left: 0 }}
                className="img-fluid"
              />
              <div
                style={{
                  position: "relative",
                  zIndex: 1,
                  paddingTop: "40px",
                  paddingLeft: "20px",
                  marginBottom: "200px",
                }}
              >
                <PhoneOutlinedIcon className="icon" />
                <p className="banner-title">How We Can Help You</p>
                <p style={{ color: "white", fontSize: "20px" }}>
                  If you need any help, contact us
                </p>
                <PhoneOutlinedIcon
                  style={{ fontSize: "20px", color: "d57315" }}
                />
                <span style={{ color: "white" }}>+91-73588 05707</span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  );
}

export default Exports;
