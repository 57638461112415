import React from "react";
import "../styles/Carousel.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/Carousel.css";
import Slide1 from "../assets/xdvsf.webp";
import Slide2 from "../assets/gdgdg.webp";
import { BrowserRouter as Router, Link, Routes, Route } from "react-router-dom";
function CustomPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        left: "10px",
        zIndex: 1,
        marginTop: "-250px",
      }}
      onClick={onClick}
    />
  );
}

function CustomNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        right: "10px",
        zIndex: 1,
        marginTop: "-250px",
      }}
      onClick={onClick}
    />
  );
}

function Carousel() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    fade: true, // Set fade property to true for fade animation
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <Slider {...settings}>
      <div className="slide-content">
        <img src={Slide1} alt="Image 1" className="carousel-img" />
        <div
          className="slide-text"
          style={{ marginTop: "-550px", animation: "slide-in 1s ease-out" }}
        >
          <button
            style={{
              backgroundColor: "#d57315",
              color: "white",
              fontSize: "26px",
              padding: "10px",
            }}
          >
            Welcome To
          </button>
          <p style={{ fontSize: "55px", marginTop: "-20px" }}>
            HIGH INDIA INTERNATIONAL MARKETING
          </p>
          <p style={{ fontSize: "25px" }} className="df">
            A overview of High India International Marketing and its commitment
            to excellence in agriculture exports.
          </p>
          <button
            style={{
              backgroundColor: "#d57315",
              height: "60px",
              width: "150px",
              color: "white",
              fontSize: "16px",
              marginTop: "20px",
              marginBottom: "600px",
            }}
          >
            <Link
              to="/about"
              style={{ color: "white", textDecoration: "none" }}
            >
              More About
            </Link>
          </button>
        </div>
      </div>
      <div className="slide-content">
        <img src={Slide2} alt="Image 1" className="carousel-img" />
        <div
          className="slide-text"
          style={{
            marginTop: "-550px",
            marginBottom: "230px",
            animation: "slide-in 1s ease-out",
          }}
        >
          <button
            style={{
              backgroundColor: "#d57315",
              color: "white",
              fontSize: "26px",
              padding: "10px",
            }}
          >
            The Best
          </button>
          <p style={{ fontSize: "70px", marginTop: "-20px" }}>
            Agriculture Products
          </p>
          <p style={{ fontSize: "25px" }} className="df">
            Welcome readers to the diverse world of agriculture products,
            highlighting their importance in sustaining global food security and
            nourishing communities.
          </p>
          <button
            style={{
              backgroundColor: "#d57315",
              height: "60px",
              width: "150px",
              color: "white",
              fontSize: "16px",
              marginTop: "20px",
            }}
          >
            <Link
              to="/products/sugar"
              style={{ color: "white", textDecoration: "none" }}
            >
              Our Products
            </Link>
          </button>
        </div>
      </div>
    </Slider>
  );
}

export default Carousel;
