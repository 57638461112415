import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import {
  BrowserRouter as Router,
  NavLink,
  Routes,
  Route,
} from "react-router-dom";
function Navbar4() {
  return (
    <Container>
      <Row className="all-height mt-5">
        <Col lg={3} xs={6}>
          <NavLink to="/bengal" className="btn" activeClassName="active">
            Bengal Gram
          </NavLink>
          <NavLink to="/horse" className="btn" activeClassName="active">
            Horse Gram
          </NavLink>
          <NavLink to="/green" className="btn" activeClassName="active">
            Green Gram
          </NavLink>
          <NavLink to="/dhall" className="btn" activeClassName="active">
            Dhall
          </NavLink>
          <NavLink to="/cashew" className="btn" activeClassName="active">
            Cashew Nut
          </NavLink>
          <NavLink to="/arcanut" className="btn" activeClassName="active">
            Arcanut
          </NavLink>
        </Col>
      </Row>
    </Container>
  );
}

export default Navbar4;
