import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Footer from "./Footer";
import spices from "../assets/NUTMEG.webp";
import Navbar3 from "./Navbar3";
import Productbg from "./Productbg";
function Nutmeg() {
  return (
    <div>
      <Productbg />
      <Container>
        <Row className="all-height">
          <Col lg={3} xs={6}>
            <Navbar3 />
          </Col>
          <Col lg={9}>
            <img src={spices} className="img-fluid" alt="Sugar" />
            <h5 className="title mt-3">ABOUT</h5>
            <p className="content1">
              Nutmeg, with its warm, aromatic flavor and subtle sweetness, is a
              beloved spice that has been cherished for centuries in culinary
              traditions around the world. Derived from the seeds of the
              evergreen tree Myristica fragrans, nutmeg boasts a rich content of
              bioactive compounds that contribute to its unique taste and
              potential health benefits.
            </p>
            <p className="content1">
              The primary bioactive compound in nutmeg is myristicin, which
              lends the spice its characteristic aroma and flavor. Myristicin is
              a volatile oil with aromatic properties and has been studied for
              its potential medicinal effects, including its antimicrobial and
              antioxidant properties. Additionally, nutmeg contains other
              essential oils such as elemicin and safrole, which further enhance
              its aromatic profile.
            </p>
            <p className="content1">
              In addition to volatile oils, nutmeg contains a variety of other
              phytochemicals, including phenolic compounds like quercetin and
              kaempferol, as well as terpenoids and alkaloids. These compounds
              contribute to nutmeg's flavor, aroma, and potential health
              benefits.
            </p>
            <p className="content1">
              Nutmeg is not only prized for its culinary uses but also valued
              for its potential medicinal properties. In traditional medicine,
              nutmeg has been used to alleviate digestive issues, reduce
              inflammation, and promote relaxation. However, it's essential to
              use nutmeg in moderation, as excessive consumption can lead to
              adverse effects. Whether grated fresh or ground into powder,
              nutmeg adds depth and complexity to both sweet and savory dishes,
              from baked goods and desserts to curries and sauces. Its rich
              content of bioactive compounds makes nutmeg not only a delightful
              culinary ingredient but also a potential source of
              health-promoting benefits when consumed in moderation as part of a
              balanced diet.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Nutmeg;
