import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import bgimg from "../assets/download.jpg";
import Footer from "./Footer";
import spices from "../assets/ASAFOETIDA.webp";
import Navbar3 from "./Navbar3";
import Productbg from "./Productbg";
function Asafoetida() {
  return (
    <div>
      <Productbg />

      <Container>
        <Row className="all-height">
          <Col lg={3} xs={6}>
            <Navbar3 />
          </Col>
          <Col lg={9}>
            <img src={spices} className="img-fluid" alt="Sugar" />
            <h5 className="title mt-3">ABOUT</h5>
            <p className="content1">
              It is a resinous gum derived from the roots of several species of
              Ferula, a perennial herb native to Iran and Afghanistan. It's
              commonly used as a spice in cooking, particularly in Indian
              cuisine, where it adds a unique flavor and aroma to dishes.
            </p>
            <p className="content1">
              The content of asafoetida can vary depending on factors such as
              its purity, the processing method, and any additives used. Pure
              asafoetida typically contains about 40-64% resin, 25% gum, 10-17%
              volatile oil, and 1-10% ash. Thea main active compound responsible
              for its pungent odor and flavor is called ferulic acid.
            </p>
            <p className="content1">
              When using asafoetida in cooking, it's usually added in very small
              quantities because of its strong and pungent aroma. Asafoetida is
              often used as a substitute for onion and garlic in dishes,
              especially in vegetarian cooking where these ingredients are
              avoided.
            </p>
            <p className="content1">
              In commercial products, especially powdered asafoetida, it's
              common to find it mixed with other ingredients like rice flour or
              wheat flour to reduce its potency and make it easier to handle.
              Always check the ingredient list if you're looking for specific
              information about the content of asafoetida in a particular
              product.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Asafoetida;
