import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import bgimg from "../assets/download.jpg";
import Footer from "./Footer";
import spices from "../assets/PEPPER.webp";
import Navbar3 from "./Navbar3";
import Productbg from "./Productbg";
function Pepper() {
  return (
    <div>
      <Productbg />

      <Container>
        <Row className="all-height">
          <Col lg={3} xs={6}>
            <Navbar3 />
          </Col>
          <Col lg={9}>
            <img src={spices} className="img-fluid" alt="Sugar" />
            <h5 className="title mt-3">ABOUT</h5>
            <p className="content1">
              Black Pepper: The most common type of pepper used in culinary
              applications is black pepper, which comes from the berries of the
              Piper nigrum plant. Black pepper contains the compound piperine,
              which gives it its characteristic pungent flavor. The piperine
              content can range from 5% to 9% by weight in black pepper.
            </p>
            <p className="content1">
              White Pepper: White pepper is also derived from the same berries
              as black pepper, but it's processed differently. The outer skin is
              removed before drying, resulting in a milder flavor compared to
              black pepper. White pepper contains similar compounds to black
              pepper but may have a slightly lower piperine content.
            </p>
            <p className="content1">
              Chili Peppers: Chili peppers, such as cayenne, jalapeño, habanero,
              and others, contain capsaicinoids, which are responsible for their
              spicy heat. The capsaicin content varies depending on the type and
              variety of chili pepper. For example, a mild chili like the bell
              pepper has very low capsaicin content, while a hot chili like the
              habanero pepper has a much higher concentration.
            </p>
            <p className="content1">
              Sichuan Pepper: Sichuan pepper, also known as Szechuan pepper, is
              not actually a true pepper but rather the dried husks of the
              prickly ash tree's berries. It contains compounds such as
              sanshools, which give it a unique numbing and tingling sensation
              rather than heat. The exact content of these compounds can vary.
            </p>
            <p className="content1">
              Pink Peppercorns: Pink peppercorns are not true pepper either;
              they are the dried berries of the Peruvian pepper tree. They have
              a mild, sweet flavor with a hint of spiciness but lack the
              piperine or capsaicin found in true peppers.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Pepper;
