import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Footer from "./Footer";
import spices from "../assets/ANISE.webp";
import Navbar3 from "./Navbar3";
import Productbg from "./Productbg";
function Anise() {
  return (
    <div>
      <Productbg />
      <Container>
        <Row className="all-height">
          <Col lg={3} xs={6}>
            <Navbar3 />
          </Col>
          <Col lg={9}>
            <img src={spices} className="img-fluid" alt="Sugar" />
            <h5 className="title mt-3">ABOUT</h5>
            <p className="content1">
              Anise, with its distinctive licorice-like flavor and aroma, is a
              flavorful spice that has been cherished for centuries in culinary
              and medicinal traditions around the world. Derived from the seeds
              of the flowering plant Pimpinella anisum, anise boasts a rich
              content of bioactive compounds that contribute to its unique taste
              and potential health benefits.
            </p>
            <p className="content1">
              The primary bioactive compound in anise is anethole, which gives
              the spice its characteristic sweet, aromatic flavor reminiscent of
              licorice. Anethole is a volatile oil with potent antimicrobial
              properties and has been studied for its potential medicinal
              effects, including its ability to alleviate digestive issues and
              soothe coughs and sore throats.
            </p>
            <p className="content1">
              In addition to anethole, anise contains other essential oils, such
              as estragole and methyl chavicol, which further enhance its
              aromatic profile and contribute to its potential health benefits.
              Anise also contains various phytochemicals, including flavonoids
              and phenolic compounds, which have antioxidant properties and may
              help protect against oxidative stress and inflammation.
            </p>
            <p className="content1">
              Anise is not only prized for its culinary uses but also valued for
              its potential medicinal properties. In traditional medicine, anise
              has been used to aid digestion, relieve gas and bloating, and
              alleviate symptoms of respiratory ailments. Additionally, anise is
              often used as a flavoring agent in liqueurs, baked goods, and
              confections, adding a delightful sweetness and depth of flavor.
            </p>
            <p className="content1">
              Whether used whole, ground, or as an essential oil, anise adds a
              unique and aromatic touch to a wide range of dishes and beverages,
              from savory stews and roasts to sweet treats like cookies, cakes,
              and candies. Its rich content of bioactive compounds makes anise
              not only a delightful culinary ingredient but also a potential
              source of health-promoting benefits when consumed as part of a
              balanced diet.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Anise;
