import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import {
  BrowserRouter as Router,
  NavLink,
  Routes,
  Route,
} from "react-router-dom";
function Navbar3() {
  return (
    <Container>
      <Row className="all-height mt-5">
        <Col lg={3} xs={6}>
          <NavLink to="/ginger" className="btn" activeClassName="active">
            Ginger
          </NavLink>
          <NavLink to="/cinnamon" className="btn" activeClassName="active">
            Cinnamon
          </NavLink>
          <NavLink to="/pepper" className="btn" activeClassName="active">
            Pepper
          </NavLink>
          <NavLink to="/tamarind" className="btn" activeClassName="active">
            Tamarind
          </NavLink>
          <NavLink to="/asafoetida" className="btn" activeClassName="active">
            Asafoetida
          </NavLink>
          <NavLink to="/turmeric" className="btn" activeClassName="active">
            Turmeric
          </NavLink>
          <NavLink to="/funnel" className="btn" activeClassName="active">
            Funnel
          </NavLink>
          <NavLink to="/garlic" className="btn" activeClassName="active">
            Garlic
          </NavLink>
          <NavLink to="/mustard" className="btn" activeClassName="active">
            Mustard
          </NavLink>
          <NavLink to="/nutmeg" className="btn" activeClassName="active">
            Nutmeg
          </NavLink>
          <NavLink to="/Anise" className="btn" activeClassName="active">
            Anise
          </NavLink>
          <NavLink to="/cumin" className="btn" activeClassName="active">
            Cumin
          </NavLink>
        </Col>
      </Row>
    </Container>
  );
}

export default Navbar3;
