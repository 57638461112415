import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Footer from "./Footer";
import spices from "../assets/janggery.webp";
import Navbar5 from "./Navbar5";
import Productbg from "./Productbg";
function Jaggery() {
  return (
    <div>
      <Productbg />
      <Container>
        <Row className="all-height">
          <Col lg={3} xs={6}>
            <Navbar5 />
          </Col>
          <Col lg={9}>
            <img src={spices} className="img-fluid" alt="Sugar" />
            <h5 className="title mt-3">ABOUT</h5>
            <p className="content1">
              Jaggery candy, also known as gur or panela, is a traditional
              sweetener that has been used for centuries in various cuisines
              around the world, particularly in South Asia, Latin America, and
              Africa. Made from the concentrated sap of sugarcane or the sap of
              the date palm tree, jaggery candy boasts a rich content of
              nutrients and bioactive compounds, making it a healthier
              alternative to refined sugar.
            </p>
            <p className="content1">
              The primary component of jaggery candy is sucrose, similar to that
              found in refined sugar. However, unlike refined sugar, which
              undergoes extensive processing and refining, jaggery candy retains
              much of the natural vitamins and minerals present in the raw
              sugarcane or date palm sap. These include iron, calcium,
              magnesium, potassium, and various B vitamins, such as vitamin B6
              and folate.
            </p>
            <p className="content1">
              In addition to sucrose and vitamins and minerals, jaggery candy
              also contains small amounts of other compounds, including glucose,
              fructose, and organic acids. These compounds contribute to
              jaggery's distinctive flavor profile, which is often described as
              rich, caramel-like, and slightly smoky.
            </p>

            <p className="content1">
              Furthermore, jaggery candy is rich in antioxidants, particularly
              phenolic compounds, which possess anti-inflammatory and
              free-radical scavenging properties. These antioxidants may help
              protect against oxidative stress and inflammation, reducing the
              risk of chronic diseases such as heart disease, diabetes, and
              cancer. While jaggery candy is a delicious and nutritious
              sweetener, it's essential to consume it in moderation as part of a
              balanced diet. Like any sweetener, jaggery candy is high in
              calories and can contribute to weight gain and other health issues
              when consumed excessively. Therefore, it's best to enjoy jaggery
              candy as an occasional treat rather than a regular part of your
              diet, while also appreciating its nutritional benefits compared to
              refined sugar.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Jaggery;
