import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import bgimg from "../assets/download.jpg";
import Footer from "./Footer";
import spices from "../assets/TAMARIND.webp";
import Navbar3 from "./Navbar3";
import Productbg from "./Productbg";
function Tamarind() {
  return (
    <div>
      <Productbg />

      <Container>
        <Row className="all-height">
          <Col lg={3} xs={6}>
            <Navbar3 />
          </Col>
          <Col lg={9}>
            <img src={spices} className="img-fluid" alt="Sugar" />
            <h5 className="title mt-3">ABOUT</h5>
            <p className="content1">
              Tamarind is a fruit that comes from the tamarind tree (Tamarindus
              indica) and is commonly used as a flavoring agent in various
              cuisines around the world. The content of tamarind can vary
              depending on its form, whether it's fresh tamarind pods, tamarind
              paste, tamarind concentrate, or tamarind powder.
            </p>
            <p className="content1">
              In general, tamarind is known for its tangy taste, which comes
              from its high tartaric acid content. Tartaric acid gives tamarind
              its characteristic sour flavor. Tamarind also contains other
              organic acids, such as acetic acid, ascorbic acid (vitamin C), and
              citric acid.
            </p>
            <p className="content1">
              Apart from acids, tamarind also contains sugars, primarily glucose
              and fructose, which contribute to its sweetness and overall flavor
              profile. Tamarind is also a good source of dietary fiber,
              particularly soluble fiber, which can aid in digestion and promote
              gut health.
            </p>
            <p className="content1">
              The exact content of tamarind in different forms can vary
              depending on factors such as the variety of tamarind, the
              processing method, and any additional ingredients added to
              tamarind products. If you're looking for specific nutritional
              information or tamarind content in a particular product, it's best
              to check the ingredient list or nutritional label.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Tamarind;
