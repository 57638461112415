import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import {
  BrowserRouter as Router,
  NavLink,
  Routes,
  Route,
} from "react-router-dom";
import Footer from "./Footer";
import spices from "../assets/MAIZE FLOUR.webp";
import Productbg from "./Productbg";
function Maize() {
  return (
    <div>
      <Productbg />
      <Container>
        <Row className="all-height">
          <Col lg={3} xs={6}>
            <NavLink to="/maize" className="btn" activeClassName="active">
              Maize Flour
            </NavLink>
            <NavLink to="/ragi" className="btn" activeClassName="active">
              Ragi Flour
            </NavLink>
          </Col>
          <Col lg={9}>
            <img src={spices} className="img-fluid" alt="Sugar" />
            <h5 className="title mt-3">ABOUT</h5>
            <p className="content1">
              Maize flour, derived from the ground kernels of maize (also known
              as corn), is a versatile ingredient that has been a dietary staple
              in many cultures for centuries. It boasts a rich content of
              nutrients and bioactive compounds, making it not only a flavorful
              addition to various dishes but also a source of potential health
              benefits.
            </p>
            <p className="content1">
              The primary component of maize flour is starch, which makes up the
              majority of its composition. Starch is a complex carbohydrate that
              provides a significant source of energy when consumed.
              Additionally, maize flour contains dietary fiber, which aids in
              digestion, promotes satiety, and supports overall gastrointestinal
              health.
            </p>
            <p className="content1">
              In addition to carbohydrates and fiber, maize flour also contains
              essential vitamins and minerals. It is a good source of B
              vitamins, including thiamine (vitamin B1), niacin (vitamin B3),
              and folate (vitamin B9), which are important for energy
              metabolism, nerve function, and red blood cell production. Maize
              flour also provides minerals such as magnesium, phosphorus, and
              potassium, which play crucial roles in bone health, muscle
              function, and fluid balance within the body.
            </p>
            <p className="content1">
              Furthermore, maize flour contains phytochemicals such as
              carotenoids, including beta-carotene and lutein, which are
              antioxidants that help protect cells from damage caused by free
              radicals. These compounds contribute to the vibrant yellow color
              of maize flour and may also offer potential health benefits,
              including supporting eye health and reducing the risk of chronic
              diseases such as heart disease and certain cancers.
            </p>
            <p className="content1">
              Whether used in traditional dishes like tortillas, cornbread, or
              porridges, or incorporated into modern recipes for baked goods,
              soups, and stews, maize flour provides both nutritional value and
              culinary versatility. Its rich content of nutrients and bioactive
              compounds makes maize flour a valuable ingredient for promoting
              overall health and well-being when included as part of a balanced
              diet.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Maize;
