import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Footer from "./Footer";
import spices from "../assets/MUSTARD SEEDS.webp";
import Navbar3 from "./Navbar3";
import Productbg from "./Productbg";
function Mustard() {
  return (
    <div>
      <Productbg />
      <Container>
        <Row className="all-height">
          <Col lg={3} xs={6}>
            <Navbar3 />
          </Col>
          <Col lg={9}>
            <img src={spices} className="img-fluid" alt="Sugar" />
            <h5 className="title mt-3">ABOUT</h5>
            <p className="content1">
              Mustard, a condiment with a rich history dating back thousands of
              years, owes its distinctive flavor and aroma to a unique blend of
              bioactive compounds found in its seeds. The content of mustard
              seeds comprises various phytochemicals, with glucosinolates being
              one of the most abundant. Glucosinolates are sulfur-containing
              compounds that give mustard its characteristic pungency and
              contribute to its potential health benefits.
            </p>
            <p className="content1">
              When mustard seeds are crushed or ground and mixed with liquid, an
              enzyme reaction occurs, leading to the formation of allyl
              isothiocyanate. This compound is responsible for the sharp, spicy
              flavor that is synonymous with mustard. Additionally, mustard
              seeds contain other bioactive compounds, such as sinapic acid and
              sinapine, which further enhance its flavor profile and potential
              health-promoting properties.
            </p>
            <p className="content1">
              Mustard is not only prized for its culinary uses but also valued
              for its potential health benefits. Research suggests that the
              bioactive compounds found in mustard seeds may possess
              anti-inflammatory, antimicrobial, and antioxidant properties,
              making mustard a flavorful addition to a healthy diet.
            </p>
            <p className="content1">
              Whether used as a spread, a sauce, or a seasoning, mustard
              continues to be a versatile and beloved condiment enjoyed by
              people around the world. Its unique flavor and potential health
              benefits make it a staple ingredient in kitchens and cuisines
              across diverse cultures and culinary traditions.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Mustard;
