import React from "react";
import Head from "./components/Head";
import Navbar from "./components/Navbar";

function App() {
  return (
    <div>
      <Head />
      <Navbar />
    </div>
  );
}
export default App;
