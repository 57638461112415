import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import bgimg from "../assets/download.jpg";
import Footer from "./Footer";
import spices from "../assets/BENGAL GARAM.webp";
import Navbar4 from "./Navbar4";
import Productbg from "./Productbg";
function Bengal() {
  return (
    <div>
      <Productbg />
      <Container>
        <Row className="all-height">
          <Col lg={3} xs={6}>
            <Navbar4 />
          </Col>
          <Col lg={9}>
            <img src={spices} className="img-fluid" alt="Sugar" />
            <h5 className="title mt-3">ABOUT</h5>
            <p className="content1">
              Bengal gram, also known as chickpeas or chana dal, is a versatile
              legume that has been a dietary staple in many cuisines around the
              world, particularly in South Asia and the Mediterranean region.
              Derived from the seeds of the Cicer arietinum plant, Bengal gram
              boasts a rich content of nutrients and bioactive compounds, making
              it not only a flavorful addition to various dishes but also a
              source of potential health benefits.
            </p>
            <p className="content1">
              One of the primary components of Bengal gram is protein, making it
              an excellent plant-based protein source for vegetarians and
              vegans. Protein is essential for muscle growth and repair, as well
              as for the production of enzymes and hormones within the body.
              Bengal gram also contains dietary fiber, which aids in digestion,
              promotes satiety, and helps regulate blood sugar levels, making it
              beneficial for individuals managing diabetes or seeking to
              maintain a healthy weight.
            </p>
            <p className="content1">
              In addition to protein and fiber, Bengal gram is rich in vitamins
              and minerals, including folate, iron, magnesium, and potassium.
              Folate is essential for DNA synthesis and cell division, making it
              particularly important during pregnancy for fetal development.
              Iron is necessary for oxygen transport in the blood, while
              magnesium and potassium are essential for nerve function, muscle
              contraction, and fluid balance within the body.
            </p>
            <p className="content1">
              Furthermore, Bengal gram contains phytochemicals such as
              polyphenols and flavonoids, which possess antioxidant properties
              and may help protect against oxidative stress and inflammation.
              These compounds contribute to Bengal gram's potential health
              benefits, including supporting heart health, reducing the risk of
              chronic diseases, and promoting overall well-being. Whether used
              to make traditional dishes like chana masala, hummus, or falafel,
              or incorporated into modern recipes for salads, soups, and stews,
              Bengal gram offers both nutritional value and culinary
              versatility. Its rich content of nutrients and bioactive compounds
              makes Bengal gram a valuable ingredient for promoting overall
              health and well-being when included as part of a balanced diet.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Bengal;
