import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import bgimg from "../assets/download.jpg";
import Footer from "./Footer";
import spices from "../assets/HORSE GRAM.webp";
import Navbar4 from "./Navbar4";
import Productbg from "./Productbg";
function Horse() {
  return (
    <div>
      <Productbg />
      <Container>
        <Row className="all-height">
          <Col lg={3} xs={6}>
            <Navbar4 />
          </Col>
          <Col lg={9}>
            <img src={spices} className="img-fluid" alt="Sugar" />
            <h5 className="title mt-3">ABOUT</h5>
            <p className="content1">
              Horse gram, also known as Macrotyloma uniflorum, is a lesser-known
              but highly nutritious legume that has been cultivated for
              centuries in various parts of the world, particularly in South
              Asia and Africa. Derived from the seeds of the horse gram plant,
              this legume boasts a rich content of nutrients and bioactive
              compounds, making it a valuable addition to various cuisines and a
              source of potential health benefits.
            </p>
            <p className="content1">
              One of the primary components of horse gram is protein, making it
              an excellent plant-based protein source for vegetarians and
              vegans. Protein is essential for muscle growth and repair, as well
              as for the production of enzymes and hormones within the body.
              Horse gram also contains dietary fiber, which aids in digestion,
              promotes satiety, and helps regulate blood sugar levels, making it
              beneficial for individuals managing diabetes or seeking to
              maintain a healthy weight.
            </p>
            <p className="content1">
              In addition to protein and fiber, horse gram is rich in vitamins
              and minerals, including folate, iron, calcium, and magnesium.
              Folate is essential for DNA synthesis and cell division, making it
              particularly important during pregnancy for fetal development.
              Iron is necessary for oxygen transport in the blood, while calcium
              and magnesium are vital for bone health, nerve function, and
              muscle contraction.
            </p>
            <p className="content1">
              Furthermore, horse gram contains phytochemicals such as
              polyphenols and flavonoids, which possess antioxidant properties
              and may help protect against oxidative stress and inflammation.
              These compounds contribute to horse gram's potential health
              benefits, including supporting heart health, reducing the risk of
              chronic diseases, and promoting overall well-being. Whether used
              to make traditional dishes like horse gram curry, soups, or
              salads, or incorporated into modern recipes for stews, stir-fries,
              and sprouted salads, horse gram offers both nutritional value and
              culinary versatility. Its rich content of nutrients and bioactive
              compounds makes horse gram a valuable ingredient for promoting
              overall health and well-being when included as part of a balanced
              diet.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Horse;
