import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import bgimg from "../assets/bg.webp";

function Productbg() {
  return (
    <div>
      <Row>
        <Col
          lg={12}
          style={{ height: "100vh", width: "100%", overflow: "hidden" }}
        >
          <img
            src={bgimg}
            style={{ height: "50%", width: "100%", objectFit: "cover" }}
            alt="background"
          />
        </Col>
      </Row>
    </div>
  );
}

export default Productbg;
