import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Footer from "./Footer";
import spices from "../assets/TURMERIC.webp";
import Navbar3 from "./Navbar3";
import Productbg from "./Productbg";
function Turmeric() {
  return (
    <div>
      <Productbg />
      <Container>
        <Row className="all-height">
          <Col lg={3} xs={6}>
            <Navbar3 />
          </Col>
          <Col lg={9}>
            <img src={spices} className="img-fluid" alt="Sugar" />
            <h5 className="title mt-3">ABOUT</h5>
            <p className="content1">
              Turmeric, derived from the rhizomes of the Curcuma longa plant, is
              renowned for its vibrant yellow color and potent medicinal
              properties. Its content comprises a rich array of bioactive
              compounds, chief among them being curcuminoids. These
              curcuminoids, including curcumin, demethoxycurcumin, and
              bisdemethoxycurcumin, typically make up around 3-7% of turmeric's
              composition. Curcumin, in particular, has garnered significant
              attention for its anti-inflammatory, antioxidant, and potential
              anticancer effects.
            </p>
            <p className="content1">
              Additionally, turmeric contains essential oils, which contribute
              to its distinctive aroma and flavor. These oils, such as
              turmerone, ar-turmerone, and zingiberene, are known to possess
              their own therapeutic properties, including anti-inflammatory and
              neuroprotective effects. Furthermore, turmeric is a source of
              proteins, fibers, vitamins, and minerals, all of which contribute
              to its nutritional profile and potential health benefits.
            </p>
            <p className="content1">
              The composition of turmeric can vary based on factors such as the
              geographic origin, cultivation methods, and processing techniques
              employed. Nonetheless, whether consumed fresh, dried, or as a
              powdered spice, turmeric remains a versatile and valuable
              ingredient in culinary and medicinal applications alike.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Turmeric;
