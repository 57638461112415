import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Footer from "./Footer";
import spices from "../assets/POPPY SEEDS.webp";
import Navbar6 from "./Navbar6";
import Productbg from "./Productbg";
function Poppy() {
  return (
    <div>
      <Productbg />
      <Container>
        <Row className="all-height">
          <Col lg={3} xs={6}>
            <Navbar6 />
          </Col>
          <Col lg={9}>
            <img src={spices} className="img-fluid" alt="Sugar" />
            <h5 className="title mt-3">ABOUT</h5>
            <p className="content1">
              The cinnamon content in foods can vary depending on the recipe and
              how it's prepared. Cinnamon is derived from the inner bark of
              several species of trees belonging to the genus Cinnamomum. The
              main active compound in cinnamon is cinnamaldehyde, which gives
              cinnamon its characteristic flavor and aroma.
            </p>
            <p className="content1">
              When using ground cinnamon, it typically contains around 1-2%
              cinnamaldehyde by weight. However, the concentration of
              cinnamaldehyde can vary depending on factors such as the type of
              cinnamon (Ceylon cinnamon vs. cassia cinnamon), how it's
              processed, and its age.
            </p>
            <p className="content1">
              Cinnamon is commonly used as a spice in both sweet and savory
              dishes, as well as in beverages like tea and coffee. It's also
              used in traditional medicine for its potential health benefits,
              which include anti-inflammatory, antioxidant, and antimicrobial
              properties.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Poppy;
