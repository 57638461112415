import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Footer from "./Footer";
import spices from "../assets/ARCA NUT.webp";
import Navbar4 from "./Navbar4";
import Productbg from "./Productbg";
function Arcanut() {
  return (
    <div>
      <Productbg />
      <Container>
        <Row className="all-height">
          <Col lg={3} xs={6}>
            <Navbar4 />
          </Col>
          <Col lg={9}>
            <img src={spices} className="img-fluid" alt="Sugar" />
            <h5 className="title mt-3">ABOUT</h5>
            <p className="content1">
              Areca nuts, also known as betel nuts or supari, are the seeds of
              the Areca catechu palm tree, native to South and Southeast Asia.
              These nuts have a long history of cultural and social significance
              in the region, often used in religious rituals, traditional
              ceremonies, and social gatherings. Despite their cultural
              importance, areca nuts are associated with various health risks,
              particularly when consumed in conjunction with other ingredients
              such as betel leaf and slaked lime, in a mixture known as paan.
            </p>
            <p className="content1">
              One of the primary bioactive compounds in areca nuts is arecoline,
              a potent alkaloid with stimulant properties. Arecoline acts on the
              central nervous system, producing effects similar to nicotine and
              caffeine, including increased alertness, euphoria, and heightened
              senses. However, chronic use of areca nuts, especially when
              combined with other ingredients in paan, has been linked to
              several health issues, including addiction, oral cancer,
              cardiovascular diseases, and gastrointestinal disorders.
            </p>
            <p className="content1">
              In addition to arecoline, areca nuts contain tannins, flavonoids,
              and other phytochemicals, some of which possess antioxidant
              properties. However, the potential health benefits of these
              compounds are overshadowed by the significant health risks
              associated with areca nut consumption, particularly when used
              habitually or in large quantities.
            </p>
            <p className="content1">
              Despite their cultural significance and traditional use, the World
              Health Organization (WHO) has classified areca nut as a Group 1
              carcinogen, indicating that there is sufficient evidence to
              support its carcinogenicity in humans. As a result, the
              consumption of areca nuts, particularly in the form of paan, is
              discouraged by health authorities worldwide, and efforts are
              underway to raise awareness about the associated health risks and
              promote cessation among affected populations. In summary, while
              areca nuts hold cultural significance in certain regions and have
              been used traditionally for various purposes, their consumption is
              associated with significant health risks, particularly concerning
              oral health and cancer. As such, it is essential for individuals
              to be aware of these risks and to make informed choices regarding
              their use of areca nuts.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Arcanut;
