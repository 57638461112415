import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Footer from "./Footer";
import spices from "../assets/SANDLE.webp";
import Navbar6 from "./Navbar6";
import Productbg from "./Productbg";
function Sandal() {
  return (
    <div>
      <Productbg />
      <Container>
        <Row className="all-height">
          <Col lg={3} xs={6}>
            <Navbar6 />
          </Col>
          <Col lg={9}>
            <img src={spices} className="img-fluid" alt="Sugar" />
            <h5 className="title mt-3">ABOUT</h5>
            <p className="content1">
              Sandalwood, renowned for its distinctive fragrance and myriad
              uses, is a prized aromatic wood derived from the heartwood of the
              Santalum tree genus, particularly Santalum album. Native to the
              Indian subcontinent, sandalwood has been revered for centuries in
              various cultures for its calming aroma, spiritual significance,
              and numerous therapeutic properties.
            </p>
            <p className="content1">
              The primary bioactive compounds in sandalwood are santalols,
              particularly alpha-santalol and beta-santalol. These compounds are
              responsible for the characteristic scent of sandalwood and are
              known for their soothing and relaxing effects on the mind and
              body. Sandalwood oil, extracted from the wood through steam
              distillation, is highly valued in aromatherapy for its ability to
              promote relaxation, reduce anxiety, and enhance mood.
            </p>
            <p className="content1">
              In addition to santalols, sandalwood contains other phytochemicals
              such as sesquiterpenes, flavonoids, and phenolic compounds, which
              possess antioxidant and anti-inflammatory properties. These
              compounds contribute to sandalwood's potential health benefits,
              including skin-soothing, anti-aging, and antimicrobial effects.
            </p>

            <p className="content1">
              Sandalwood is also used extensively in traditional medicine
              systems such as Ayurveda, where it is believed to balance the
              doshas (energies) of the body and promote overall well-being. It
              is used internally in the form of powder or extracts for digestive
              issues, respiratory ailments, and urinary tract infections, among
              other conditions. Externally, sandalwood paste is applied to the
              skin for its cooling and healing properties, making it a popular
              ingredient in skincare products and beauty rituals.
            </p>
            <p className="content1">
              While sandalwood is prized for its aromatic and therapeutic
              qualities, it's essential to use sustainably sourced and ethically
              harvested sandalwood products to preserve this precious resource
              for future generations. Whether enjoyed for its calming aroma,
              therapeutic benefits, or spiritual significance, sandalwood
              continues to captivate and inspire people around the world with
              its timeless allure.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Sandal;
