import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Footer from "./Footer";
import spices from "../assets/CUMIN.webp";
import Navbar3 from "./Navbar3";
import Productbg from "./Productbg";
function Cumin() {
  return (
    <div>
      <Productbg />
      <Container>
        <Row className="all-height">
          <Col lg={3} xs={6}>
            <Navbar3 />
          </Col>
          <Col lg={9}>
            <img src={spices} className="img-fluid" alt="Sugar" />
            <h5 className="title mt-3">ABOUT</h5>
            <p className="content1">
              Cumin, with its warm, earthy flavor and aromatic profile, is a
              beloved spice that has been integral to cuisines around the world
              for centuries. Derived from the seeds of the Cuminum cyminum
              plant, cumin boasts a rich content of bioactive compounds that
              contribute to its distinctive taste and potential health benefits.
            </p>
            <p className="content1">
              The primary bioactive compound in cumin is cuminaldehyde, which
              imparts the spice with its characteristic aroma and flavor.
              Cuminaldehyde is a volatile oil with antimicrobial properties and
              has been studied for its potential medicinal effects, including
              its ability to aid digestion and promote gastrointestinal health.
            </p>
            <p className="content1">
              In addition to cuminaldehyde, cumin contains other essential oils,
              such as cymene and terpenoids, which further enhance its aromatic
              profile and contribute to its potential health benefits. Cumin is
              also rich in phytochemicals like flavonoids and phenolic
              compounds, which possess antioxidant properties and may help
              protect against oxidative stress and inflammation.
            </p>
            <p className="content1">
              Cumin is not only prized for its culinary uses but also valued for
              its potential medicinal properties. In traditional medicine, cumin
              has been used to alleviate digestive issues, improve appetite, and
              relieve symptoms of respiratory ailments. Additionally, cumin is a
              versatile spice that can be used whole or ground in a wide range
              of dishes, from savory curries and stews to spice blends and
              marinades.
            </p>
            <p className="content1">
              Whether toasted whole for added depth of flavor or ground into a
              fine powder for easy incorporation into dishes, cumin adds a
              distinctive and flavorful touch to countless recipes. Its rich
              content of bioactive compounds makes cumin not only a flavorful
              culinary ingredient but also a potential source of
              health-promoting benefits when consumed as part of a balanced
              sdiet.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Cumin;
