import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Footer from "./Footer";
import spices from "../assets/CHILLIES.webp";
import Navbar6 from "./Navbar6";
import Productbg from "./Productbg";
function Chillis() {
  return (
    <div>
      <Productbg />
      <Container>
        <Row className="all-height">
          <Col lg={3} xs={6}>
            <Navbar6 />
          </Col>
          <Col lg={9}>
            <img src={spices} className="img-fluid" alt="Sugar" />
            <h5 className="title mt-3">ABOUT</h5>
            <p className="content1">
              Chili peppers, with their fiery heat and vibrant colors, are a
              staple ingredient in cuisines around the world, adding both flavor
              and spice to a wide variety of dishes. These peppers belong to the
              Capsicum genus and come in a range of shapes, sizes, and heat
              levels, from mild and sweet to intensely hot.
            </p>
            <p className="content1">
              The primary bioactive compound responsible for the heat in chili
              peppers is capsaicin. Capsaicin activates heat receptors in the
              mouth and creates a sensation of heat, spiciness, and sometimes
              pain. The heat level of a chili pepper is measured on the Scoville
              scale, with milder peppers such as bell peppers ranking low and
              hotter varieties like habaneros and ghost peppers ranking much
              higher.
            </p>
            <p className="content1">
              In addition to capsaicin, chili peppers contain an array of
              vitamins, minerals, and phytochemicals that contribute to their
              nutritional value and potential health benefits. They are
              particularly rich in vitamin C, providing a significant portion of
              the recommended daily intake in just a small serving. Chili
              peppers also contain vitamins A, B6, and K, as well as minerals
              like potassium and manganese.
            </p>

            <p className="content1">
              Furthermore, chili peppers are rich in antioxidants, particularly
              carotenoids such as beta-carotene and capsanthin, which give them
              their vibrant colors. These antioxidants help neutralize harmful
              free radicals in the body, reducing oxidative stress and
              inflammation and lowering the risk of chronic diseases such as
              heart disease, cancer, and diabetes. Moreover, chili peppers
              contain compounds that have been studied for their potential
              health benefits, including anti-inflammatory, analgesic, and
              metabolism-boosting properties. Some research suggests that
              capsaicin may aid in weight management by increasing metabolism
              and reducing appetite, although more studies are needed to confirm
              these effects conclusively.
            </p>
            <p className="content1">
              While chili peppers can add excitement and flavor to dishes, it's
              essential to use them judiciously, especially if you're sensitive
              to spicy foods or have digestive issues. Start with small amounts
              and gradually increase as desired, and be sure to wash your hands
              thoroughly after handling chili peppers to avoid irritation.
              Overall, chili peppers are a versatile and nutritious ingredient
              that can spice up your culinary creations while providing a range
              of health benefits.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Chillis;
